import React from "react"
import Layout from "../components/layout"
// import ErrorPage from "../components/404Page/errorPage.js"

const NotFoundPage = () => (
  <div className="not-found-wrapper">
    <Layout>
      {/* <div id="notFoundPage">
        <ErrorPage></ErrorPage>
      </div> */}
    </Layout>
  </div>
)

export default NotFoundPage;
